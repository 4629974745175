import React from "react";
import "./About.css";
import profile from "../../images/jpg/profile_pic_about.jpg";

function About({ navbar }) {
  return (
    <>

      {navbar}

      <div className="about_layout">

        <img src={profile} />

        <span>

        
        <p>Coach Charlie is a dedicated mentor with a remarkable knack
for guiding mentees through the thrilling uncertainties of stocks
and stock options. His artistic flair creates eye-catching content
that makes complex concepts and strategies not only digestible
but also enjoyable to learn! With a keen ability to recognize
patterns in technical analysis, Coach Charlie makes the
investing process accessible and straightforward for everyone.
</p>
<p>
His talent for simplifying intricate information has empowered
countless individuals to quickly grasp essential concepts,
building their confidence for real-world market success.
Whether it’s stocks or stock options, Coach Charlie is always
eager to dive deeper and lend a helping hand. His passion for
teaching has transformed the lives of family, friends, peers, and
even his own!
</p>
<p>
He has guided individuals to retire from their careers, enabling
them to chase their passions, and assisted mothers in replacing
their income so they can savor more quality time with their
children. He inspires mothers to kickstart investment portfolios
for their kids, giving them a head start in life, and supports
young men seeking alternatives to the traditional 9-to-5 grind,
helping them pursue their true purpose.
</p>
<p>
But that’s not all! Coach Charlie also works with individuals
released from incarceration, providing them with renewed
focus and a clear path forward. He’s a lifeline for single mothers
facing financial challenges, helping them navigate their way to
stability. Plus, he empowers men feeling trapped by their
financial situations to learn the ropes of investing while
involving their sons in the exciting journey.
</p>
<p>
A true servant of the Most High, Coach Charlie embodies a
spirit of generosity and knowledge, offering invaluable insights
to everyone on their journey to financial freedom and personal
fulfillment. Join him, and let the adventure begin!
</p>
        </span>

      </div>

    </>
  );
}

export default About;
