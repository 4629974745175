import React, { useContext, useEffect, useRef, useState } from 'react'
import { collection, doc, getDocs, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore'
import "./Materials.css"
import { auth, db } from '../../utils/Firebase-Config'
import { UserProfileContext } from '../../utils/UserProfileContext'
import DeleteDoc from '../DeleteDoc/DeleteDoc'
import Uploader from '../Uploader/Uploader'
import CreateCourseForm from '../Forms/CreateCourseForm'
import Video from '../Video/Video'
import Update from '../Update/Update'

function Materials({id, data}) {

  const {status} = useContext(UserProfileContext)

  const anchorRef = useRef(null) 

    const [lessons, setLessons] = useState(null)
    const [homework, setHomework] = useState(null)
    const [resources, setResources] = useState(null)
    const [title, setTitle] = useState("")
    const [header, setHeader] = useState("")
    const [text, setText] = useState("")
    const [isPublished, setIsPublished] = useState(false)
    const [toggleEditTitle, setToggleEditTitle] = useState(false)
    const [toggleEditHeader, setToggleEditHeader] = useState(false)
    const [toggleEditText, setToggleEditText] = useState(false)


    useEffect(() => {
    const q = query(collection(db, "courses", id, "material"), orderBy('timestamp'));

      onSnapshot(q, (querySnapshot) => {

        let temp_lessons = []
        let temp_homework = []
        let temp_resource = []

        querySnapshot.forEach((doc) => {
            // console.log(doc.data().path);

            if (doc.data().section === "lesson") {temp_lessons.push({ id: doc.id, data: doc.data() })}
            if (doc.data().section === "homework") {temp_homework.push({ id: doc.id, data: doc.data() })}
            if (doc.data().section === "resource") {temp_resource.push({ id: doc.id, data: doc.data() })}

          });    

          setLessons(temp_lessons)
          setHomework(temp_homework)
          setResources(temp_resource)

      }, [])

      setTimeout(() => {
        anchorRef.current?.scrollIntoView({behavior:"smooth"})
      }, 2500);      

    }, [id])



  return (
    <div ref={anchorRef} className="profile_stage">

    
    <div className="materials_menu" style={{display: status === "admin" ? "grid" : "none"}}>
      <Uploader directory={`/courses/${id}/material`} modulePointsRequired={data.pointsRequired} /><DeleteDoc path={`/courses/`} id={id} />
    </div>
    
      {toggleEditTitle ? <input type="text" style={{height: toggleEditTitle ? "30px" : "0" }} placeholder="New Title" onChange={(e) => setTitle(e.target.value)} onBlur={ async () => {
        if (!title || title === "" || title === undefined) {
          setToggleEditTitle(false)
          return}

        const docRef = doc(db, "courses", id);
    
        await updateDoc(docRef, {
    
        //KEYS & VALUES THAT NEED UPDATING
        title: title,
    
        },{ merge: true });
  
        setToggleEditTitle(false)

      }}/> : <h1 onClick={() => setToggleEditTitle(true)}>{data.title}</h1>}
      
      {toggleEditHeader ? <input type="text" style={{height: toggleEditHeader ? "30px" : "0" }} placeholder="New Sub-Header" onChange={(e) => setHeader(e.target.value)} onBlur={ async () => {
        if (!header || header === "" || header === undefined) {
          setToggleEditHeader(false)
          return}

        const docRef = doc(db, "courses", id);
    
        await updateDoc(docRef, {
    
        //KEYS & VALUES THAT NEED UPDATING
        header: header,
    
        },{ merge: true });
  
        setToggleEditHeader(false)
      }}/> : <h2 onClick={() => setToggleEditHeader(true)}>{data.header}</h2>}

      {toggleEditText ? <input type="text" style={{height: toggleEditText ? "30px" : "0" }} placeholder="New Description" onChange={(e) => setText(e.target.value)} onBlur={ async () => {
        if (!text || text === "" || text === undefined) {
          setToggleEditText(false)
          return}

        const docRef = doc(db, "courses", id);
    
        await updateDoc(docRef, {
    
        //KEYS & VALUES THAT NEED UPDATING
        text: text,
    
        },{ merge: true });
  
        setToggleEditText(false)
      }}/> : <p onClick={() => setToggleEditText(true)}>{data.text}</p>}

    <div className='material_wrapper'>

      <h1>Lesson:</h1>

      {lessons && lessons.map((doc, index) => {

        if (doc.data.type === "image") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card'>
            <img src={doc.data.path} />
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
            <Update />
          </div>
          </div>
          )
        }
        
        if (doc.data.type === "video") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card'>
            {/* <video src={doc.data.path} muted controls/> */}
            <Video url={doc.data.path} id={doc.id} uid={auth.currentUser.uid}/>
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <div className="col">
            <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
            <Update path={`/courses/${id}/material/`} id={doc.id} />
            </div>
          </div>
          </div>
          )
        }
        
        if (doc.data.type === "pdf") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card pdf'>
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <a href={doc.data.path} target="_blank">{doc.data.caption}</a>
            <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
          </div>
          </div>
          )
        }
      })}

    </div>

    <div className='material_wrapper'>

      <h1>Homework:</h1>

      {homework && homework.map((doc, index) => {

        if (doc.data.type === "image") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card'>
            <img src={doc.data.path} />
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <div className="col">
              <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
              <Update />
            </div>
          </div>
          </div>
          )
        }
        
        if (doc.data.type === "video") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card'>
            {/* <video src={doc.data.path} muted controls/> */}
            <Video url={doc.data.path} text={doc.data.text}/>
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <div className="col">
              <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
              <Update />
            </div>
          </div>
          </div>
          )
        }
        
        if (doc.data.type === "pdf") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card pdf'>
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <div className="col">
            <a href={doc.data.path} target="_blank">{doc.data.caption}</a>
              <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
              <Update />
            </div>
          </div>
          </div>
          )
        }
      })}

    </div>

    <div className='material_wrapper'>

      <h1>Resources:</h1>

      {resources && resources.map((doc, index) => {

        if (doc.data.type === "image") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card'>
            <img src={doc.data.path} />
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <div className="col">
              <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
              <Update />
            </div>
          </div>
          </div>
          )
        }
        
        if (doc.data.type === "video") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card'>
            {/* <video src={doc.data.path} muted controls/> */}
            <Video url={doc.data.path} text={doc.data.text}/>
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <div className="col">
              <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
              <Update />
            </div>
          </div>
          </div>
          )
        }
        
        if (doc.data.type === "pdf") {
          return (
            <div className="wrapper" key={index}>
            <div className='media_card pdf'>
            <h1>{doc.data.caption}</h1>
            <p>{doc.data.text}</p>
            <a href={doc.data.path} target="_blank">{doc.data.caption}</a>
            <div className="col">
              <DeleteDoc path={`/courses/${id}/material/`} id={doc.id} />
              <Update />
            </div>
          </div>
          </div>
          )
        }
      })}

    </div>

    </div>
  )
}

export default Materials