import React, { useContext, useEffect, useState } from "react";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import Avatar from "../../components/Avatar/Avatar";
import Subscription from "../../components/Subscription/Subscription";
import { auth, db } from "../../utils/Firebase-Config";
import "./Profile.css";
import Facetime from "../../components/Facetime/Facetime";
import JoinLive from "../../components/Facetime/JoinLive";
import Materials from "../../components/Materials/Materials";
import CreateCourseForm from "../../components/Forms/CreateCourseForm";
import AssignmentIcon from '@mui/icons-material/Assignment';
import { GeneralUtilsContext } from "../../utils/GeneralUtilsContext";
import CancelIcon from '@mui/icons-material/Cancel';

function Profile({ navbar }) {

  const {getModulesMenu, modulesMenu} = useContext(GeneralUtilsContext)

  const [value, setValue] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [status, setStatus] = useState(null);
  const [created, setCreated] = useState(null);
  const [log, setLog] = useState(null);
  const [points, setPoints] = useState(0);

  const [toggle, setToggle] = useState(false);
  const [live, setLive] = useState(false);
  const [room, setRoom] = useState(null);

  const [modules, setModules] = useState(null);
  const [stage, setStage] = useState(null);


  //User
  useEffect(() => {
    const docRef = doc(db, "users", auth.currentUser.uid);

    onSnapshot(docRef, (doc) => {
      setName(doc.data().name);
      setEmail(doc.data().email);
      setStatus(doc.data().status);
      setCreated(doc.data().created);
      setLog(doc.data().log);
      setPoints(doc.data().points);
    });
  }, []);

  //Check for Live Event
  useEffect(() => {
    const docRef = doc(db, "settings", "activity");

    try {
      onSnapshot(docRef, (doc) => {
        setLive(doc.data().isLive);
        setRoom(doc.data().room);
      });
    } catch (error) {}
  }, []);

  const update = async (e) => {
    e.preventDefault();

    if (!value) {
      return;
    }

    const docRef = doc(db, "users", auth.currentUser.uid);

    await updateDoc(
      docRef,
      {
        //KEYS & VALUES THAT NEED UPDATING
        name: value,
      },
      { merge: true }
    );

    setToggle(false);
  };

  //Load & Update Module List on Points Change
  useEffect(() => {
    const getQuerySnapshot = async () => {
      try {

        const q = query(
          collection(db, "courses"),
          orderBy("pointsRequired"),
          where("type", "==", "subscription"),
          // where("pointsRequired", "<=", points)
        );

        onSnapshot(q, (querySnapshot) => {
          let temp = [];

          querySnapshot.forEach((doc) => {
            // console.log({ id: doc.id, data: doc.data() });
            temp.push({ id: doc.id, data: doc.data() });
          });

          setModules(temp);
        });
      } catch (error) {
        console.log(error);
      }
    };

    getQuerySnapshot();
  }, [points]);

  // const watch = () => {

  // }
  return ( 
    <>
      {navbar}

      <div className="profile_layout">

        <div className="modules_list" style={{right: modulesMenu ? "0" : "-100vw"}}>

          {status === "admin" ? <CreateCourseForm /> : null}

          {modules &&
            modules.map((module, index) => {

              if (module.data.pointsRequired <= points) {
                return (
                  <button
                    className="mod_btn"
                    key={index}
                    onClick={(e) => {
                      setStage(<Materials id={module.id} data={module.data} />)
                      getModulesMenu(e)
                    }}
                  >
                    {module.data.title}
                  </button>                  
                )
              } else {
                return (
                  <div style={{opacity: "50%", filter: "blur(.75px)"}}
                    className="mod_btn"
                    key={index}
                    onClick={() => {
                      window.alert("You do not currently have the requied points necessary to view this lesson.")
                    }}
                  >
                    {module.data.title}
                  </div>                  
                )                
              }

          })}

          <button onClick={(e) => getModulesMenu(e)}><CancelIcon /></button>
        </div>        

        <div className="profile_hud">

          <div className="col">
            <div
              className="live_indicator"
              style={{ backgroundColor: live ? "aqua" : "transparent" }}
            ></div>
            {live ? "Charles, is Live!" : null}
          </div>

          <Avatar isUpdateable={true} />

          <div className="info">
            <span>
              Name: <h2>{name}</h2>
              <button
                onClick={() => {
                  if (toggle) {
                    setToggle(false);
                  } else {
                    setToggle(true);
                  }
                }}
              >
                +
              </button>
            </span>

            <form onSubmit={(e) => update(e)}>
              <input
                type="text"
                placeholder="Update name"
                style={{ height: toggle ? "30px" : "0px" }}
                onChange={(e) => setValue(e.target.value)}
              />
            </form>
            <span>
              Email: <h2>{email}</h2>
            </span>
            <span>
              Status: <h2>{status}</h2>
            </span>
            <span>
              Points: <h2>{points}</h2>
            </span>
            <span>
              <h5>Date Created: </h5>
              <h6>{created}</h6>
            </span>
            <span>
              <h5>Last Log In: </h5>
              <h6>{log}</h6>
            </span>
          </div>

          <Subscription />
        </div>

        {stage}

        <div className="live_view" style={{ display: live ? "grid" : "none" }}>
          {/* <JoinLive joinCode={room} /> */}
        </div>

        

      </div>
      
    </>
  );
}

export default Profile;
